
import { defineComponent, computed, ref } from 'vue';
import { useStore } from 'vuex';
import Banner from '@/components/Banner.vue';
import CONSTS from '@/utils/constants';
import { IAllocationRequest, TWalletType } from '@/types/user';

export default defineComponent({
  name: 'AllocationConfirmedBanner',
  components: { Banner },
  props: {},
  setup() {
    const store = useStore();
    const requestedAllocation = computed((): IAllocationRequest => {
      return store.getters['user/allocation'].maxInvestedAmount || 0;
    });
    const acceptedAllocation = computed((): number => {
      // return 250;
      return store.getters['user/allocationConfirmed'];
    });
    const tokenCost = computed((): number => {
      return store.getters['app/tokenCost'];
    });
    const totalTokens = computed((): number => {
      return Math.floor(acceptedAllocation.value / tokenCost.value);
    });
    const walletAddress = computed((): IAllocationRequest => {
      return store.getters['user/allocation']?.walletAddress || '';
    });
    const walletType = ref<TWalletType | '' | null>(
      store.getters['user/allocation']?.walletType || null
    );
    return {
      CONSTS,
      walletAddress,
      walletType,
      totalTokens,
      acceptedAllocation,
      requestedAllocation,
    };
  },
});
