
import Icon from '@/components/Icon.vue';
import Banner from '@/components/Banner.vue';
import AllocationConfirmedBanner from '@/components/AllocationConfirmedBanner.vue';
import CONSTS from '@/utils/constants';
import { useStore } from 'vuex';
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  name: 'contract',
  components: { Icon, Banner, AllocationConfirmedBanner },
  setup() {
    const store = useStore();
    const curState = computed(() => store.getters['user/currentState']);
    const userStates = CONSTS.USER_STATES;
    const isSignEligible = computed<boolean>(
      () => curState.value === userStates.SAFT_ELIGIBLE
    );
    const isSignStarted = computed<boolean>(
      () => curState.value === userStates.SAFT_STARTED
    );
    const isSaftNotSigned = computed<boolean>(
      () => curState.value === userStates.SAFT_NOT_SIGNED
    );
    const isSaftSigned = computed<boolean>(
      () => curState.value === userStates.SAFT_SIGNED
    );
    const saftLink = computed<string>(() => store.getters['user/saftLink']);
    const busy = ref<boolean>(false);
    const startSign = () => {
      busy.value = true;
      store.dispatch('user/startSaftSign').finally(() => {
        busy.value = false;
      });
    };
    return {
      busy,
      CONSTS,
      saftLink,
      startSign,
      isSaftSigned,
      isSignStarted,
      isSignEligible,
      isSaftNotSigned,
    };
  },
});
