import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!

  return (_openBlock(), _createBlock(_component_banner, {
    type: _ctx.CONSTS.ALERT_TYPES.SUCCESS,
    title: `Confirmed Allocation: ${_ctx.acceptedAllocation.toLocaleString()} USDT`,
    content: [
      `Confirmed USDT allocation: ${_ctx.acceptedAllocation.toLocaleString()} out of ${_ctx.requestedAllocation.toLocaleString()} USDT.`,
      `Confirmed token allocation: ${_ctx.totalTokens.toLocaleString()} GOOD.`,
      `Your wallet: ${_ctx.walletAddress} on ${_ctx.CONSTS.BLOCKCHAINS_DATA[_ctx.walletType].name}`
    ],
    class: "mb2"
  }, null, 8, ["type", "title", "content"]))
}